import React from 'react';
import { Helmet } from 'react-helmet';
import { Landing } from '../components/Landing'
import { Locations } from '../components/Locations'
import { Recommendations } from '../components/Recommendations'
import { RSVP } from '../components/RSVP';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Angelina ♡ Joseph</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <main className="font-[Satoshi-Light] text-lg" style={{ backgroundColor: '#FFF7F7' }}>
        <Landing />
        <Locations />
        <Recommendations />
        <RSVP />
      </main>
    </>
  )
}

export default IndexPage
