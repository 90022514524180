import React from 'react';
import { Section } from '../shared/Section';

export function Landing() {
  return (
    <Section className="h-full flex flex-col justify-between text-center" wrapperClassName="h-screen j_a_bg-filtered relative">
      <video autoPlay loop muted playsInline>
        <source src="https://res.cloudinary.com/iopsychas/video/upload/v1647529782/a-J/bg-video.mp4" type='video/mp4' />
      </video>
      <div>
        <div className="mt-40 flex items-center relative text-slate-100">
          <hr className="flex-1 border-t-4 border-slate-100"/>
          <h1 className="flex-auto text-7xl sm:text-8xl lg:text-9xl font-[Boska-Variable]">Angelina & Joseph</h1>
          <hr className="flex-1 border-t-4 border-slate-100"/>
        </div>
        <div className="text-xl sm:text-3xl mt-9 relative text-slate-100">May 28 2022 · Thessaloniki, Greece</div>
      </div>
      <div>
        <div className="text-sm sm:text-xl uppercase relative text-slate-100">You are invited to our wedding</div>
        <div className="mb-5 md:mb-7 relative text-slate-100">↓</div>
      </div>
    </Section>
  );
}
