import React from 'react';
import { Title } from '../Title';
import { Link } from '../Link';

export function Directions() {
  return (
    <div
      className="p-7 rounded-sm -mt-32 text-gray-600"
      style={{ backgroundColor: 'rgb(239, 238, 232)' }}
    >
      <Title>Getting there</Title>
      <p className="mt-5">
        The closest airport is Thessaloniki's Airport Makedonia (SKG). With May being within the
        tourism season in Greece, there is a very good chance you will find direct flights to
        Thessaloniki from your nearest airport.
      </p>
      <p className="mt-1">
        Alternatively, you can either fly to one of the islands in the Ionian sea like Corfu or
        Zante and drive to Thessaloniki or fly to Athens and drive or take a domestic flight to
        Thessaloniki.
      </p>
      <p className="mt-5">
        Find flights:
        <Link to="https://www.skyscanner.net/" className="ml-3">Skyscanner</Link>,
        <Link to="https://www.google.com/flights/" className="ml-3">Google Flights</Link>,
        <Link to="https://www.easyjet.com/en" className="ml-3">EasyJet</Link>,
        <Link to="https://www.ryanair.com/gb/en" className="ml-3">Ryanair</Link>,
        <Link to="https://www.jet2.com/#flights" className="ml-3">Jet2</Link>
      </p>
      <p className="mt-5">
        Please note that depending on the COVID situation at the time, there might be travel
        restrictions in place. Make sure you check the official guidelines for
        <Link to="https://travel.gov.gr/#/">Greece</Link> and the
        <Link to="https://www.gov.uk/foreign-travel-advice/greece">UK</Link>. You can also check
        <Link to="https://www.skyscanner.net/travel-restrictions?vaccinationStatus=fully-vaccinated">
          this handy tool from Skyscanner
        </Link>
        .
      </p>
    </div>
  );
}
