import React from 'react';
import { Section } from '../shared/Section';
import { Activities } from './Activities';
import { Directions } from './Directions';
import { Stay } from './Stay'

export function Recommendations() {
  return (
    <Section className="md:p-10 flex flex-col gap-2 max-w-6xl" style={{ backgroundColor: 'hsl(160deg 23% 79%)'}}>
      <Directions />
      <Stay />
      <Activities />
    </Section>
  );
}
