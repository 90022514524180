import React from 'react';

export function Link({ children, className = '', to }) {
  return (
    <a
      href={to}
      className={`border-b-4 ml-1 ${className}`}
      style={{ borderColor: '#D79798',}}
    >
      {children}
    </a>
  )
}
