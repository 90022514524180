import React from 'react';
import { Section } from '../shared/Section'

export function Locations() {
  return (
    <Section className="h-full min-h-screen mb-48 md:mb-32 flex justify-center">
      <div>
        <h2 className="flex-auto text-6xl md:text-7xl font-[Boska-Variable] text-slate-800 mt-24 text-center underline underline-offset-2" style={{ textDecorationColor: '#D79798'}}>
          Join us in Thessaloniki
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch md:space-x-10 lg:space-x-48 flex-auto mt-10 md:mt-40">
          <div className="max-w-96 p-10 flex flex-col justify-center items-center">
            <img src="wedding-rings.png" height="100" width="100" className="w-auto h-20 md:h-24"/>
            <h2 className="text-3xl lg:text-4xl font-[Boska-Variable] mt-5">Ceremony</h2>
            <p className="mt-5 text-slate-600 text-xl lg:text-2xl">Saturday, 28th May, 2022</p>
            <p className="text-slate-600 text-xl lg:text-2xl">7:00pm</p>
            <p className="text-slate-600 text-xl lg:text-2xl md:mt-3">Three Holy Hierarchs Church</p>
            <a href="https://goo.gl/maps/vqw29bDHHvVmG7XCA" className="mt-5 md:mt-10 uppercase hover:underline font-medium text-md">
              › Get directions
            </a>
          </div>
          <div className="max-w-96 p-10 flex flex-col justify-center items-center">
            <img src="cheers.png" height="100" width="100" className="w-auto h-20 md:h-24" />
            <h2 className="text-3xl lg:text-4xl font-[Boska-Variable] mt-5">Reception</h2>
            <p className="mt-5 text-slate-600 text-xl lg:text-2xl">Saturday, 28th May, 2022</p>
            <p className="text-slate-600 text-xl lg:text-2xl">8:30pm</p>
            <p className="text-slate-600 text-xl lg:text-2xl md:mt-3">Noesis</p>
            <a href="https://goo.gl/maps/LE9s4zE2kXBgPpAc6" className="mt-5 md:mt-10 uppercase hover:underline font-medium text-md">
              › Get directions
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}
