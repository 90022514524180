import React from 'react';
import { Section } from '../shared/Section';


export function RSVP() {
  return (
    <Section className="text-center py-20 sm:py-32 md:py-48">
      <h2 className="text-4xl sm:text-5xl text-center font-[Boska-Light]">RSVP</h2>
      <p className="p-2 mt-5 md:mt-10 text-lg sm:text-2xl">To RSVP and any enquiries contact either Angelina or Joseph</p>
      <p className="mt-20 font-[Comforter] text-4xl">We'd love to see you there</p>
    </Section>
  );
}
