import React from 'react';
import { ActivityItem } from './ActivityItem';
import { ActivityList } from './ActivityList';

export function Activities() {
  return (
    <div className="flex flex-wrap gap-2">
      <ActivityList title="Eat">
        <ActivityItem link="https://www.tripadvisor.co.uk/Attraction_Review-g189473-d5890333-Reviews-Ladadika_District-Thessaloniki_Thessaloniki_Region_Central_Macedonia.html" title="Ladadika">The "how to be Greek" vibe"</ActivityItem>
        <ActivityItem link="https://goo.gl/maps/ikfmCuG6Cc63C3Ha8" title="Ergon Agora">Traditional Greek cuisine with a twist</ActivityItem>
        <ActivityItem link="https://goo.gl/maps/3vWokNswmoSV1w45A" title="Opsopoion Maganeiai">Mediterranean gastronomy</ActivityItem>
      </ActivityList>
      <ActivityList title="Drink">
        <ActivityItem link="https://goo.gl/maps/QtezPN8BtKTn7uDd8" title="Mistral Seaside Bar">For Greek island vibes</ActivityItem>
        <ActivityItem link="https://goo.gl/maps/mbjUqYFJ8nWTqZv3A" title="Allegro">For amazing sea views</ActivityItem>
        <ActivityItem link="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjg-5S08c32AhVKecAKHQoeB6UQFnoECAsQAQ&url=https%3A%2F%2Fwww.tripadvisor.co.uk%2FRestaurant_Review-g189473-d12567968-Reviews-Cin_Cin-Thessaloniki_Thessaloniki_Region_Central_Macedonia.html&usg=AOvVaw1NP5GTA4BcSX54BrGb190p" title="Cin Cin">For cocktails, wine and a unique atmosphere</ActivityItem>
      </ActivityList>
      <ActivityList title="Visit">
        <ActivityItem link="https://theculturetrip.com/europe/greece/articles/ano-poli-thessalonikis-most-colourful-and-historic-neighbourhood/" title="Kastra & Ano Poli">A journey through time</ActivityItem>
        <ActivityItem link="https://goo.gl/maps/DafkGPNAKCwqbS4t6" title="White Tower">The city's landmark</ActivityItem>
        <ActivityItem link="https://goo.gl/maps/4TK8yqpS9PL1rxgVA" title="Museum of Byzantine Culture">For history lovers</ActivityItem>
      </ActivityList>
    </div>
  )
}
