import React from 'react';
import { Title } from '../Title';

export function ActivityList({ children, title }) {
  return (
    <div className="flex-auto w-64 p-7 rounded-sm" style={{backgroundColor: 'rgb(239, 238, 232)'}}>
      <Title>{title}</Title>
      {children}
    </div>
  );
}
