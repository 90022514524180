import React from 'react';

export function ActivityItem({children, link, title}) {
  return (
    <div className="py-3">
      <div className="flex">
        <h2 className="text-lg font-serif text-gray-800">{title}</h2>
        <a href={link} className="ml-auto">→</a>
      </div>
      <p className="mt-1 text-gray-600">{children}</p>
    </div>
  );
}
