import React from 'react';
import { Title } from '../Title';

export function Area({ airbnbLink, children, hotelLink, title }) {
  return (
    <section className="w-64 flex-auto p-7 flex flex-col">
      <Title>{title}</Title>
      <p className="mt-4 text-gray-600">{children}</p>

      <div className="flex flex-wrap sm:space-x-5 mt-auto">
        <div className="mt-5 sm:mt-8 w-full sm:w-auto">
          <a href={airbnbLink} className="py-2 font-semibold">
            Find Airbnbs
            <span className="ml-2">→</span>
          </a>
        </div>
        <div className="mt-2 sm:mt-8 w-full sm:w-auto">
          <a href={hotelLink} className="py-2 font-semibold">
            Find Hotels
            <span className="ml-2">→</span>
          </a>
        </div>
      </div>
    </section>
  );
}
