import React from 'react';
import { Link } from '../Link';
import { Area } from './Area';

export function Stay() {
  return (
    <div className="flex flex-wrap rounded-sm" style={{backgroundColor: 'rgb(239, 238, 232)'}}>
      <Area
        airbnbLink="https://www.airbnb.co.uk/s/Thessaloniki--Greece/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_dates%5B%5D=february&flexible_trip_dates%5B%5D=march&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&query=Thessaloniki%2C%20Greece&place_id=ChIJ7eAoFPQ4qBQRqXTVuBXnugk&checkin=2022-05-26&checkout=2022-05-30&ne_lat=40.63715402939591&ne_lng=23.083512358066343&sw_lat=40.54173189847736&sw_lng=22.926957182285093&zoom=13"
        hotelLink="https://www.booking.com/searchresults.en-gb.html?aid=397594&label=gog235jc-1BCAEoggI46AdIM1gDaFCIAQGYAQm4AQfIAQzYAQHoAQGIAgGoAgO4AuDQtY8GwAIB0gIkZGRkOWZjZDEtNTdmMi00YzYxLWE5NmQtNDg0YmI1MmJhMmNh2AIF4AIB&sid=cd4cf8362ccf226d659db493a6a48cc6&sb=1&sb_lp=1&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.en-gb.html%3Faid%3D397594%3Blabel%3Dgog235jc-1BCAEoggI46AdIM1gDaFCIAQGYAQm4AQfIAQzYAQHoAQGIAgGoAgO4AuDQtY8GwAIB0gIkZGRkOWZjZDEtNTdmMi00YzYxLWE5NmQtNDg0YmI1MmJhMmNh2AIF4AIB%3Bsid%3Dcd4cf8362ccf226d659db493a6a48cc6%3Bsb_price_type%3Dtotal%26%3B&ss=Thessaloniki%2C+Macedonia%2C+Greece&is_ski_area=&checkin_year=2022&checkin_month=5&checkin_monthday=27&checkout_year=2022&checkout_month=5&checkout_monthday=30&group_adults=2&group_children=0&no_rooms=1&b_h4u_keep_filters=&from_sf=1&search_pageview_id=391766f01b8301ea&ac_suggestion_list_length=5&ac_suggestion_theme_list_length=0&ac_position=0&ac_langcode=en&ac_click_type=b&dest_id=-829252&dest_type=city&iata=SKG&place_id_lat=40.63339&place_id_lon=22.941875&search_pageview_id=391766f01b8301ea&search_selected=true&ss_raw=Thessaloniki#map_closed"
        imageSrc="city.png"
        title="City"
      >
        Staying in the city centre is the best option if you would like to keep a busy itinerary.
        With everything within walking distance you will be able to explore loads of shops, restaurants
        bars and historic places. All roads run either to the sea or parallel to the sea.
        Relics from this city's past lives, under Roman, Ottoman and Byzantine rule, are scattered around,
        with more treasures seeming to turn up all the time. So the many world class museums are well stocked.
        Here's<Link to="https://thessalonikiblog.com/15-interesting-facts-about-thessaloniki/">15 interesting facts about Thessaloniki.</Link>
      </Area>
      <Area
        airbnbLink="https://www.airbnb.co.uk/s/Thessaloniki--Greece/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_dates%5B%5D=february&flexible_trip_dates%5B%5D=march&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&query=Thessaloniki%2C%20Greece&place_id=ChIJ7eAoFPQ4qBQRqXTVuBXnugk&checkin=2022-05-26&checkout=2022-05-30&ne_lat=40.553648898545866&ne_lng=23.069714628270333&sw_lat=40.45810762946989&sw_lng=22.913159452489083&zoom=13&search_by_map=true&search_type=user_map_move"
        hotelLink="https://www.booking.com/searchresults.en-gb.html?aid=397594&label=gog235jc-1FCAEoggI46AdIM1gDaFCIAQGYAQm4AQfIAQzYAQHoAQH4AQyIAgGoAgO4AuDQtY8GwAIB0gIkZGRkOWZjZDEtNTdmMi00YzYxLWE5NmQtNDg0YmI1MmJhMmNh2AIG4AIB&sid=cd4cf8362ccf226d659db493a6a48cc6&sb=1&src=searchresults&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Fsearchresults.en-gb.html%3Faid%3D397594%3Blabel%3Dgog235jc-1FCAEoggI46AdIM1gDaFCIAQGYAQm4AQfIAQzYAQHoAQH4AQyIAgGoAgO4AuDQtY8GwAIB0gIkZGRkOWZjZDEtNTdmMi00YzYxLWE5NmQtNDg0YmI1MmJhMmNh2AIG4AIB%3Bsid%3Dcd4cf8362ccf226d659db493a6a48cc6%3Btmpl%3Dsearchresults%3Bac_click_type%3Db%3Bac_position%3D0%3Bcheckin_month%3D5%3Bcheckin_monthday%3D27%3Bcheckin_year%3D2022%3Bcheckout_month%3D5%3Bcheckout_monthday%3D30%3Bcheckout_year%3D2022%3Bclass_interval%3D1%3Bdest_id%3D-829252%3Bdest_type%3Dcity%3Bdtdisc%3D0%3Bfrom_sf%3D1%3Bgroup_adults%3D2%3Bgroup_children%3D0%3Biata%3DSKG%3Binac%3D0%3Bindex_postcard%3D0%3Blabel_click%3Dundef%3Bno_rooms%3D1%3Boffset%3D0%3Bpostcard%3D0%3Braw_dest_type%3Dcity%3Broom1%3DA%252CA%3Bsb_price_type%3Dtotal%3Bsearch_selected%3D1%3Bshw_aparth%3D1%3Bslp_r_match%3D0%3Bsrc%3Dindex%3Bsrc_elem%3Dsb%3Bsrpvid%3D4ebd66fb10640274%3Bss%3DThessaloniki%252C%2520Macedonia%252C%2520Greece%3Bss_all%3D0%3Bss_raw%3DThessaloniki%3Bssb%3Dempty%3Bsshis%3D0%26%3B&ss=Thessaloniki+Airport%2C+Thessaloniki%2C+Macedonia%2C+Greece&is_ski_area=&ssne=Thessaloniki&ssne_untouched=Thessaloniki&city=-829252&checkin_year=2022&checkin_month=5&checkin_monthday=27&checkout_year=2022&checkout_month=5&checkout_monthday=30&group_adults=2&group_children=0&no_rooms=1&from_sf=1&ss_raw=Thessaloniki%2C+&ac_position=2&ac_langcode=en&ac_click_type=b&dest_id=849&dest_type=airport&iata=SKG&place_id_lat=40.5238&place_id_lon=22.9767&search_pageview_id=4ebd66fb10640274&search_selected=true&search_pageview_id=4ebd66fb10640274&ac_suggestion_list_length=5&ac_suggestion_theme_list_length=0#map_closed"
        imageSrc="airport.png"
        title="Airport"
      >
        If you prefer to avoid the busy city and go for more relaxing options you could
        choose to stay near the airport which is around 9 miles from Thessaloniki's city centre by car.
        From there you will also be able to explore beaches towards and in the Chalkidiki area which
        are 30 to 60 minutes away. Keep in mind that renting a car would make your life much easier
        if you opted for this choice.
      </Area>
    </div>
  );
}
